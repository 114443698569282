<template>
  <svg viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.66658 2.30583C3.27606 1.91531 2.64289 1.91531 2.25237 2.30583C1.86184 2.69636 1.86184 3.32952 2.25237 3.72005L6.08661 7.55429L2.25246 11.3884C1.86193 11.779 1.86193 12.4121 2.25246 12.8027C2.64298 13.1932 3.27615 13.1932 3.66667 12.8027L7.50083 8.96851L11.3349 12.8026C11.7254 13.1931 12.3586 13.1931 12.7491 12.8026C13.1397 12.4121 13.1397 11.7789 12.7491 11.3884L8.91504 7.55429L12.7492 3.72011C13.1397 3.32958 13.1397 2.69642 12.7492 2.3059C12.3587 1.91537 11.7255 1.91537 11.335 2.3059L7.50082 6.14008L3.66658 2.30583Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCancel'
}
</script>
